<template>
  <div class="merge" v-loading="loading">
    <div>
      <!--title-->
      <div class="title">
        <div class="btnp">
          {{ $t("message.duplication.label.mergeTitle") }} {{ labelName }}
        </div>
        <div>
          <i class="el-icon-close" @click="close"></i>
        </div>
      </div>
      <!--合并取消按钮-->
      <div style="padding-top: 20px">
        <div
          style="
            color: rgba(80, 116, 148, 1);
            font-size: 16px;
            padding-left: 24px;
          "
        >
          <!-- 选择要保留的值 -->
          {{ $t("message.duplication.label.sectionTitle") }}
        </div>
        <div
          style="
            text-align: center;
            padding-top: 52px;
            padding-bottom: 12px;
            border-bottom: 1px solid rgba(240, 240, 240, 1);
          "
        >
          <el-row>
            <el-button plain size="mini" @click="combination">
              <!-- 合并 -->
              {{ $t("label.checkfield.merge") }}
            </el-button>
            <el-button plain size="mini" @click="close">
              <!-- 取消 -->
              {{ $t("label.cancel") }}
            </el-button>
          </el-row>
        </div>
      </div>
      <div style="color: rgba(80, 116, 148, 1); padding-left: 24px">
        <div style="padding-top: 20px; white-space: nowrap">
          <!-- 请选择要在合并后的记录中保留的值。高标亮显示的行表明字段中包含冲突的数据。所选的主记录将保留只读和隐藏字段值 -->
          {{ $t("message.duplication.label.sectionremar1") }}
        </div>
        <div
          style="padding-top: 18px; padding-bottom: 20px; white-space: nowrap"
        >
          <!-- 注意：所有相关记录包括的任何备注、附件和活动都将与新的合并记录相关联 -->
          {{ $t("message.duplication.label.sectionremar2") }}
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <div style="padding: 0px 24px 20px 24px">
      <table>
        <tr v-for="(item, index) in headLabel" :key="index">
          <!-- 首列 -->
          <td v-if="item.schemefieldName == 'name'"></td>
          <td
            :style="{ background: item.showInput ? '#D9EFFF' : '#F6F6F6' }"
            style="text-align: right; width: 240px"
            v-else-if="item.schemefieldName == 'id'"
          >
            <!-- 主记录 -->
            {{ $t("label.mr.mainRecord") }}
          </td>
          <td
            :style="{ background: item.showInput ? '#D9EFFF' : '#F6F6F6' }"
            style="text-align: right; width: 240px"
            v-else
          >
            {{ item.nameLabel }}
          </td>
          <!-- 循环生成的数据 -->
          <td v-for="(Sitem, Sindex) in listObj" :key="Sindex">
            <input
              :data-index="Sindex"
              :value="Sitem[item.schemefieldName]"
              ref="inputRadio"
              v-if="item.showInput"
              :checked="Sindex == colSelectIndex"
              style="margin-right: 5px"
              type="radio"
              :name="item.schemefieldName"
            />
            <span v-if="item.schemefieldName == 'id'">
              {{ $t("label-combination-record", { index: Sindex + 1 }) }}
            </span>
            <!-- 图片 -->
            <span v-else-if="item.schemefieldType == 'IMG'">
              <img
                v-if="
                  Sitem[item.schemefieldName] !== undefined &&
                  Sitem[item.schemefieldName] !== '' &&
                  Sitem[item.schemefieldName] !== null
                "
                :src="imgSrc(Sitem[item.schemefieldName])"
                style="width: 50%"
              />
            </span>
            <!-- 文件 -->
            <span v-else-if="item.schemefieldType === 'FL'">
              <span
                v-for="file in Sitem[item.schemefieldName + 'List']"
                :key="file.fileid"
              >
                {{ file.filename }}
              </span>
            </span>
            <!-- 评分 -->
            <span
              v-else-if="item.schemefieldType === 'SCORE'"
              class="cannotEdit stars"
              style="display: contents"
            >
              <span v-show="false">{{
                Sitem[item.schemefieldName] === null ||
                Sitem[item.schemefieldName] === undefined
                  ? 0
                  : Number(Sitem[item.schemefieldName])
              }}</span>
              <span
                v-for="(star, idx) in Number(item.schemefieldLength) + 1"
                v-show="idx !== 0"
                :class="{
                  show2: idx <= Number(Sitem[item.schemefieldName]),
                }"
                :key="idx"
              >
              </span>
            </span>
            <div class="choseAll" v-else>
              <span
                v-if="
                  item.apiname == 'their_project_name' ||
                  item.apiname == 'their_milestone' ||
                  item.apiname == 'project_name'
                "
                >{{ Sitem[item.schemefieldName + "ccname"] }}</span
              >
              <span v-else>
                {{
                  item.schemefieldType == "Y" ||
                  item.schemefieldType == "R" ||
                  item.schemefieldType == "MR"
                    ? `${Sitem[item.schemefieldName + "ccname"]}`
                    : Sitem[item.schemefieldName]
                }}
              </span>
              <span
                @click="choseAll(Sindex)"
                style="padding-left: 5px; cursor: pointer"
                >{{
                  [item.schemefieldName] == "name"
                    ? $t("message.duplication.label.allselectlink")
                    : ""
                }}</span
              >
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>


<script>
import { merge } from "./api.js";
import { postCombination } from "./api.js";
export default {
  name: "merge",
  data() {
    return {
      //合并记录的id
      ids: this.$route.query.ids,
      // 对象prefix
      prefix: this.$route.query.prefix,
      // 存储列信息
      headLabel: [],
      // 存储记录详情
      listObj: [],
      // 保存当前列选中
      colSelectIndex: 0,
      //存储合并信息的jsonstr
      jsonstr: [],
      //保存所有被选中的input
      allSelectedInput: [],
      //保存所有的input
      allInput: [],
      loading: false,
      labelName: this.$route.query.labelName,
      token: this.$CCDK.CCToken.getToken(),
    };
  },
  created() {},
  mounted() {
    this.loading = true;
    this.Merge();
  },
  methods: {
    //显示图片
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`;
    },
    Merge() {
      var param = {
        ids: this.ids,
        prefix: this.prefix,
      };
      merge(param)
        .then((res) => {
          let result = res.data.headLabel.filter((item, i) => {
            if (item.schemefieldName == "name") {
              res.data.headLabel.splice(i, 1);
              return item;
            }
          });
          let result1 = res.data.headLabel.filter((item, i) => {
            if (item.schemefieldName == "id") {
              res.data.headLabel.splice(i, 1);
              return item;
            }
          });
          res.data.headLabel.unshift(result1[0]);
          res.data.headLabel.unshift(result[0]);
          //双重for循环遍历res.data

          for (let key in res.data.headLabel) {
            //外层循环 控制行数 遍历当前行数据
            var colName = res.data.headLabel[key].schemefieldName;
            var colResult = [];
            for (var index in res.data.listObj) {
              colResult.push(res.data.listObj[index][colName]);
            }
            var arr = [];
            for (var newKey in colResult) {
              if (arr.indexOf(colResult[newKey]) == -1) {
                arr.push(colResult[newKey]);
              }
            }
            //数据取值全部相同 给当前列  加一个不显示按钮的属性
            if (arr.length == 1) {
              res.data.headLabel[key].showInput = false;
            } else {
              res.data.headLabel[key].showInput = true;
            }
          }
          res.data.headLabel[0].showInput = false;
          this.headLabel = res.data.headLabel;
          this.listObj = res.data.listObj;
          this.headLabel.map((item) => {
            this.listObj.map((Sitem) => {
              if (!Sitem[item.schemefieldName] == true) {
                Sitem[item.schemefieldName] = "";
                if (
                  item.schemefieldType == "Y" ||
                  item.schemefieldType == "R" ||
                  item.schemefieldType == "MR"
                ) {
                  Sitem[item.schemefieldName + "ccname"] = "";
                }
              }
              return Sitem;
            });
            return item;
          });
          this.loading = false;
        })
        .catch(() => {});
    },
    close() {
      this.$router.go(-1);
    },
    choseAll(index) {
      this.colSelectIndex = index;
    },
    combination() {
      this.loading = true;
      this.allInput = this.$refs.inputRadio;
      this.allSelectedInput = this.allInput.filter((item) => {
        return item.checked == true;
      });
      this.postCombinationResult();
    },
    //调用合并记录接口
    async postCombinationResult() {
      this.jsonstr = [];
      let orderNum = "";
      //准备主记录
      var mainRecord = {
        apiname: "majro1_alex",
        value: this.allSelectedInput[0].value,
      };
      this.listObj.map((item) => {
        if (item.id === this.allSelectedInput[0].value) {
          orderNum = item.name;
        }
      });
      this.jsonstr.push(mainRecord);
      //准备 其他记录
      //  遍历listobj   没有被选中的 存入value值
      var otherId = this.listObj.filter((item) => {
        return item.id !== this.allSelectedInput[0].value;
      });
      var arr = [];
      for (var key in otherId) {
        arr.push(otherId[key].id);
      }
      arr = arr.join(";");
      var otherRecord = {
        apiname: "othre1_alex",
        value: arr,
      };
      this.jsonstr.push(otherRecord);
      //  有input 不包含主记录
      var allSelectedInput = this.allSelectedInput.filter((item) => {
        return item.name !== "id";
      });
      for (let key in allSelectedInput) {
        //准备jsonstr
        var objListItem = {
          apiname: "",
          value: "",
        };
        // 给apiname赋值
        objListItem.apiname = allSelectedInput[key].name;
        //  给value赋值
        // eslint-disable-next-line no-unused-vars
        for (var index in this.listObj) {
          //获取当前选中的index值
          var Index = allSelectedInput[key].dataset.index;
          objListItem.value = this.listObj[Index].id;
        }
        this.jsonstr.push(objListItem);
      }
      // 无 input为主记录id
      for (let key in this.headLabel) {
        // 准备jsonstr
        let objListItem = {
          apiname: "",
          value: "",
        };
        // showInput ==false && 不是name列
        if (this.headLabel[key].showInput == false) {
          objListItem.apiname = this.headLabel[key].schemefieldName;
          objListItem.value = this.allSelectedInput[0].value;
        }
        this.jsonstr.push(objListItem);
      }
      //删除为空的数组
      this.jsonstr = this.jsonstr.filter((item) => {
        return item.apiname !== "";
      });
      this.jsonstr = JSON.stringify(this.jsonstr);
      let obj = {
        prefix: this.prefix,
        jsonstr: this.jsonstr,
      };
      let result = await postCombination(obj);
      this.loading = false;
      if (result.result) {
        this.$message.success(result.returnInfo);
      }
      if (this.$route.query.isConsole === "true") {
        this.$router.push({
          path: "/controlPanel/control",
          query: {
            caseId: this.allSelectedInput[0].value,
            caseOrder: orderNum,
            viewId: this.$route.query.viewId,
          },
        });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style scoped>
.choseAll {
  display: inline-block;
}
.merge {
  background: #ffffff;
  border-radius: 4px;
  margin: 10px;
  overflow-x: auto;
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 21px 19px 16px 24px;
  border-bottom: 3px solid rgba(240, 240, 240, 1);
}
table {
  border-collapse: collapse;
  width: 100%;
}
tr {
  height: 30px;
}
td {
  border: 1px solid #dddbda;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 14px;
  padding-right: 14px;
  min-height: 30px;
  min-width: 200px;
}
/* 表格一行一列背景色 */
tr:first-child {
  background-color: #f6f6f6;
}
/* tr>td:first-child{
  background-color: #F6F6F6;
} */
/* 评分字段显示 */
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  color: #ccc;
  float: left;
}

.stars span {
  font-size: 20px;
  margin-left: 3px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}
</style>